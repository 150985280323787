import { initializeApp } from "firebase/app";
import {
    onAuthStateChanged,
    getAuth,
    signInAnonymously,
    setPersistence,
    browserLocalPersistence,
    getIdToken,
} from "firebase/auth";
import { useAuth } from "@vueuse/firebase/useAuth";
import { useStorage } from "@vueuse/core";
import {
    initializeAppCheck,
    ReCaptchaEnterpriseProvider,
    getToken,
    setTokenAutoRefreshEnabled,
} from "firebase/app-check";

export function useFirebase(injectedConfig: any = {}) {
    const config = useRuntimeConfig();
    const apiKey = config.public?.firebaseApiKey || "";
    const projectId = config.public?.firebaseProjectId || "";
    const authDomain = `${projectId}.firebaseapp.com`;
    const appId = config.public?.firebaseAppId || "";
    const reCAPTCHAKey = config.public?.recaptchaKey || "";

    const app = initializeApp({
        projectId,
        apiKey,
        authDomain,
        appId,
    });
    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(reCAPTCHAKey),
        isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
    });
    const auth = getAuth(app);
    const { isAuthenticated, user } = useAuth(auth);

    const signIn = async () => {
        return await setPersistence(auth, browserLocalPersistence)
            .then(async () => await signInAnonymously(auth))
            .catch((error) => console.error(error));
    };
    const signOut = async () => {
        await auth.signOut();
    };
    const customer: any = useStorage("cr:customer", {
        userId: user.value?.uid,
        phoneNumber: null,
    });
    const removeCustomer = () => {
        customer.value = {
            userId: null,
            phoneNumber: null,
        };
    };
    setTokenAutoRefreshEnabled(appCheck, true);
    const getAppCheckToken = async () => {
        try {
            const token = await getToken(appCheck, false);

            return token.token;
        } catch (e) {
            return null;
        }
    };
    const getUserIdToken = async (user: any = null) => {
        try {
            console.log("getting user id token: ", user || user.value);
            return await getIdToken(user || user.value, true);
        } catch (e) {
            console.error("error getting user id token: ", e);
            return null;
        }
    };

    return {
        isAuthenticated,
        auth,
        user,
        signIn,
        onAuthStateChanged,
        customer,
        getAppCheckToken,
        signOut,
        removeCustomer,
        getUserIdToken,
        getIdToken,
    };
}
